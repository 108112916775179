import React, { useState } from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ContactUsInfo = ({ data }) => {
    const [activeId, setActiveId] = useState();
    return (
        <section className="contact-us-info-area section-space-pb">
            <div className="container-full">
                <div className="grid grid-cols-1 md:grid-cols-12">
                    <div className="md:col-span-5">
                        {data?.contactInfo.map((item) => (
                            <div
                                key={item.id}
                                className="single-info mb-12 last:mb-0"
                            >
                                <h2 className="mb-[25px]">{item.title}</h2>
                                <ul>
                                    {item.text && (
                                        <div className="">
                                            <li className="mb-[14px] last:mb-0">
                                                <a
                                                    className="google-map-link cursor-pointer text-[#064DF0]"
                                                    href="https://www.google.com.tw/maps/place/324%E6%A1%83%E5%9C%92%E5%B8%82%E5%B9%B3%E9%8E%AE%E5%8D%80%E9%95%B7%E5%AE%89%E8%B7%AF15%E5%B7%B738%E8%99%9F/@24.955757,121.1958159,18z/data=!3m1!4b1!4m5!3m4!1s0x346823bdacb5e845:0xbaf641bb4b032acc!8m2!3d24.9557553!4d121.1965638"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    - {item.text}
                                                </a>
                                            </li>
                                            <div className="mb-[14px]">
                                                -{" "}
                                                <a
                                                    className="google-map-link cursor-pointer text-[#064DF0]"
                                                    href={data?.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i className="fi fi-map-1 mr-2"></i>
                                                    街景服務
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {item.number && (
                                        <li className="mb-[14px]">
                                            <a href={`tel:${item.number}`}>
                                                <span className="text-black">
                                                    - 電話：{item.number}
                                                </span>
                                            </a>
                                        </li>
                                    )}
                                    <li className="mb-[14px]">
                                        <a href={`mailto:${item.email}`}>
                                            <span className="text-black">
                                                - 傳真：03-4947401
                                            </span>
                                        </a>
                                    </li>
                                    {item.email && (
                                        <li className="mb-[20px]">
                                            <a href={`mailto:${item.email}`}>
                                                <span className="text-black">
                                                    - 電子郵件：{item.email}
                                                </span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="md:col-span-7">
                        <div className="map-location-layer relative">
                            <GatsbyImage
                                className="w-auto cover object-cover max-h-[500px]"
                                image={getImage(data?.images[0].src)}
                                alt={data?.images[0].alt}
                            />
                            {/* {data?.items?.map((item) => (
                                <div
                                    key={item.id}
                                    className={`absolute w-3 h-3 cursor-pointer rounded-full bg-primary shadow-[0_0_0_10px_rgb(14_164_120_/_10%)] ${
                                        activeId === item.id ? "active" : ""
                                    } ${item.id}`}
                                    onClick={() => setActiveId(item.id)}
                                    onKeyPress={() => {}}
                                    role="presentation"
                                >
                                    <span
                                        className="title opacity-0 before:absolute before:bottom-[-8px] before:left-1/2 
                                        before:w-4 before:h-4 before:content-[''] before:-z-[1] before:-translate-x-1/2
                                        before:rotate-45 before:bg-white
                                     shadow-[0_10px_40px_0_rgb(0_0_0_/_15%)] text-black rounded bg-white p-[5px_15px] left-1/2 -translate-x-1/2 whitespace-nowrap text-sm font-semibold top-[-70px] absolute "
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            ))} */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ContactUsInfo.propTypes = {
    data: PropTypes.shape({
        contactInfo: PropTypes.array,
        link: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default ContactUsInfo;
